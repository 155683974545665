.app {
  text-align: center;
  display: flex;
  position: relative;
}

.loadingBg {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(88, 83, 83, 0.63);
}

.login-content {
  background: linear-gradient(145.5deg, rgba(120, 131, 156, 1) 0%, rgba(67, 74, 89, 1) 100%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.dialog {
  align-self: center;
  background-color: #fff;
  width: 356Px;
  display: flex;
  flex-direction: column;
  border-radius: 31Px;
  align-items: center;
}

.login-img {
  margin-top: 38Px;
  width: 56Px;
  height: 56Px;
  margin-bottom: 20Px;
}

.login-title {
  color: rgba(38, 38, 41, 1);
  font-size: 26Px;
  font-weight: bold;
}

.login-input-bg {
  border-radius: 89Px;
  width: 268Px;
  margin-top: 20Px;
  align-items: center;
  height: 40Px;
  border-width: 1Px;
  flex-direction: column;
  justify-content: center;
  border-color: rgba(220, 223, 230, 1);
  border-style: solid;
}

.login-input {
  border-style: hidden;
  width: 90%;
  margin-top: 5Px;
  outline: none;
  height: 30Px;
  border: none;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 39Px;
  margin-bottom: 70Px;
  width: 268Px;
  height: 40Px;
  border-radius: 36Px;
  background: linear-gradient(150.4deg, rgba(113, 123, 146, 1) 0%, rgba(33, 36, 43, 1) 100%);
}