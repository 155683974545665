.App {
  text-align: center;
  display: flex;
  position: relative;
}

.loadingBg {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(88, 83, 83, 0.63);
}

.app-content {
  background-color: rgba(244, 249, 255, 1);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: white;
}

.app-left-content {
  border-radius: 0.1875rem;
  margin-left: 0.1875rem;
  height: 95%;
  background: linear-gradient(150.4deg, rgba(113, 123, 146, 1) 0%, rgba(33, 36, 43, 1) 100%);
  width: 1.848958rem;
  flex-direction: column;
  justify-content: center;
}

.app-left-title {
  margin-left: 0.21875rem;
  display: flex;
  align-self: flex-start;
  margin-bottom: 0.15625rem;
  font-weight: bold;
  margin-top: 0.3125rem;
  font-size: 0.145833rem;
}

.app-left-date-item {
  margin-left: 0.21875rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.today-date {
  padding-right: 0.104167rem;
  font-size: 0.083333rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.052083rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.title-layout {
  margin-top: 0.432292rem;
  margin-left: 0.21875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.today-date-time {
  text-align: left;
  padding-right: 0;
  width: 0.416667rem;
}

.left-image {
  width: 0.145833rem;
  height: 0.145833rem;
  margin-right: 0.125rem;
}

.left-item-content-layout {
  display: flex;
  margin-top: 0.041667rem;
  flex-direction: row;
  margin-left: 0.21875rem;
}

.left-num-text {
  font-size: 0.25rem;
  font-weight: bold;
  color: #ffffff;
}

.left-date-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.09375rem;
}

.text-fontsize-18 {
  font-size: 0.09375rem;
  font-weight: 600;
}

.center-content {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  margin-left: 0.1875rem;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  align-content: flex-start;
  flex-direction: column;
}

.center-image {
  width: 0.197917rem;
  height: 0.197917rem;
}

.center-title {
  font-size: 0.125rem;
  margin-left: 0.114583rem;
  font-weight: bold;
  color: rgba(38, 38, 41, 1);
}

.delivery-title-layout {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.xiaocun-posted-layout {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 0.302083rem;
}

.xiaocun-keyou-layout {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 0.302083rem;
}

.center-orders-content {
  width: 4.036458rem;
  margin-top: 0.125rem;
  padding-left: 0.291667rem;
  height: 1.244792rem;
  display: flex;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 0.291667rem;
}

.center-posted-content {
  margin-top: 0.125rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 1.244792rem;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  flex-direction: column;
}

.center-keyou-content {
  margin-top: 0.125rem;
  height: 1.244792rem;
  display: flex;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  flex-direction: column;
  justify-content: center;
}

.center-item-content {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.center-item-title {
  font-size: 0.072917rem;
  color: rgba(38, 38, 41, 1);
  font-weight: bold;
}

.center-item-num {
  font-size: 0.135417rem;
  font-weight: bold;
  margin-top: 0.020833rem;
  color: rgba(255, 125, 65, 1);
}

.center-item-date {
  color: rgba(38, 38, 41, 0.5);
  font-size: 0.0625rem;
  margin-top: 0.020833rem;
}

.center-item-line {
  margin-top: 0.0625rem;
  background-color: rgba(229, 229, 229, 1);
  height: 0.005208rem;
  width: 0.765625rem;
  margin-bottom: 0.0625rem;
}

.center-item-arrow {
  height: 0.072917rem;
  width: 0.072917rem;
}

.center-item-num-key {
  white-space: pre;
  font-size: 0.0625rem;
  color: rgba(57, 57, 60, 1);
}

.center-item-add-num {
  font-size: 0.0625rem;
  color: rgba(190, 22, 16, 1);
}

.center-item-reduce-num {
  font-size: 0.0625rem;
  color: rgba(27, 153, 46, 1);
}

.center-item-add-reduce {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.0625rem;
}

.center-posted-row-item,
.center-keyou-row-item {
  display: flex;
  width: 4.036458rem;
  padding-left: 0.291667rem;
  padding-right: 0.291667rem;
  flex-direction: row;
}

.center-posted-column-item,
.center-keyou-column-item {
  display: flex;
  width: 33.3%;
  align-items: flex-start;
  flex-direction: column;
}

.center-posted-column-title,
.center-keyou-column-title {
  font-weight: bold;
  font-size: 0.072917rem;
  color: rgba(38, 38, 41, 1);
}

.center-posted-column-num {
  font-size: 0.135417rem;
  font-weight: bold;
  margin-top: 0.020833rem;
  color: rgba(58, 161, 240, 1);
}

.center-keyou-column-num {
  font-size: 0.135417rem;
  font-weight: bold;
  margin-top: 0.0625rem;
  color: rgba(58, 161, 240, 1);
}

.center-posted-column-date {
  font-size: 0.0625rem;
  margin-top: 0.020833rem;
  color: rgba(38, 38, 41, 0.5);
}

.center-keyou-column-date {
  font-size: 0.0625rem;
  margin-top: 0.0625rem;
  color: rgba(38, 38, 41, 0.5);
}

.right-content {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  margin-left: 0.1875rem;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  align-content: flex-start;
  flex-direction: column;
}

.right-order-layout {
  width: 2.760417rem;
  display: flex;
  flex-direction: column;
}

.logout-icon {
  width: 0.135417rem;
  height: 0.135417rem;
  margin-right: 0.03125rem;
}

.logout-text {
  font-weight: bold;
  font-size: 0.09375rem;
  color: rgba(51, 55, 66, 1);
  text-decoration: underline;
}

.right-popular-layout {
  width: 2.760417rem;
  display: flex;
  margin-top: 0.286458rem;
  flex-direction: column;
}

.right-people-added-layout {
  width: 2.760417rem;
  display: flex;
  margin-top: 0.286458rem;
  flex-direction: column;
}

.checkbox-layout {
  padding-left: 0.020833rem;
  padding-right: 0.020833rem;
  padding-top: 0.015625rem;
  padding-bottom: 0.015625rem;
  border-radius: 0.921875rem;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  background: linear-gradient(150.4deg, rgba(33, 36, 43, 1) 0%, rgba(113, 123, 146, 1) 100%);
  width: 1.041667rem;
  height: 0.177083rem;
}

.check-button {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 50%;
  border-radius: 0.520833rem;
  background-color: #ffffff;
  color: black;
}

.uncheck-button {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 50%;
  color: #ffffff;
}

.right-orders-rank-layout,
.right-categories-layout,
.right-added-layout {
  width: 2.552083rem;
  margin-top: 0.125rem;
  padding-left: 0.177083rem;
  padding-right: 0.03125rem;
  min-height: 1.244792rem;
  display: flex;
  background-color: #ffffff;
  border-radius: 0.1875rem;
  flex-direction: column;
}

.right-orders-rank-row-layout {
  display: flex;
  flex-direction: row;
  margin-top: 0.083333rem;
}

.right-orders-rank-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 15%;
}

.most-popular-num,
.added-num {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 10%;
}

.right-orders-region-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
}

.most-popular-name {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
}

.added-name {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 45%;
}

.right-orders-xc-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
}

.most-popular-pieces {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
}

.added-pieces {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 15%;
}

.right-orders-muncho-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
}

.most-popular-weight {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.orders-rank-title-text {
  color: #000000;
  font-size: 0.0625rem;
  font-weight: bold;
  align-self: flex-start;
}

.orders-rank-item-text {
  color: #000000;
  font-size: 0.072917rem;
  align-self: flex-start;
}

.popular-title,
.added-title {
  align-self: flex-start;
  color: rgba(38, 38, 41, 1);
  font-size: 0.09375rem;
  font-weight: bold;
  margin-top: 0.125rem;
  margin-bottom: 0.041667rem;
}

.popular-row-item,
.added-row-item {
  flex-direction: row;
  display: flex;
  margin-top: 0.067708rem;
}

.most-popular-weight {
  border-radius: 0.213542rem;
  display: flex;
  height: 0.104167rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 161, 240, 1);
}

.added-weight {
  border-radius: 0.213542rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0.104167rem;
  justify-content: center;
  background-color: rgba(52, 142, 122, 1);
}

.weight1,
.added-weight1 {
  width: 0.588542rem;
  height: 0.083333rem;
}

.weight2,
.added-weight2 {
  width: 0.494792rem;
  height: 0.083333rem;
}

.weight3,
.added-weight3 {
  width: 0.458333rem;
  height: 0.083333rem;
}

.weight4 {
  background-color: rgba(184, 203, 224, 1);
  width: 0.442708rem;
  height: 0.083333rem;
}

.added-weight4 {
  background-color: rgba(196, 207, 202, 1);
  width: 0.442708rem;
  height: 0.083333rem;
}

.added-weight5 {
  background-color: rgba(196, 207, 202, 1);
}

.weight5 {
  background-color: rgba(184, 203, 224, 1);
}

.fontsize-12 {
  font-size: 0.0625rem;
}

.fontsize-14 {
  font-size: 0.072917rem;
}

.logout-layout {
  justify-content: center;
  align-items: center;
  margin-right: 0.46875rem;
  flex-direction: row;
  display: flex;
}